@tailwind base;
@tailwind components;
@tailwind utilities;

@config "./../../tailwind.config.js";

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  color-scheme: light only;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  color: theme('colors.hg.black');
}

a {
  -webkit-appearance: none !important;
  appearance: none !important;
}

p > a {
  color: theme('colors.hg.secondary');
}

a:hover {
  color: theme('colors.hg.secondary');
}

.derma a {
  color: theme('colors.derma.primary');
}

.derma a:hover {
  color: theme('colors.derma.primary500');
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
  outline: none !important;
}

input[type='date'] {
  appearance: none;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  margin-top: -12px;
  margin-right: -4px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.font-gtUltra {
  letter-spacing: -0.038em;
}

#categorySelector::-webkit-scrollbar {
  display: none;
}

#categorySelector::-webkit-scrollbar,
#blogCategorySelector::-webkit-scrollbar {
  display: none;
}
